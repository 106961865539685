import React, { Fragment, useEffect, useContext } from 'react';
import DataContext from '../context/dataContext';
import { InView } from 'react-intersection-observer';
import { fixedActiveNavigationPartDesktop } from '../animations/animation';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Promo from '../components/Promo';
import FixedNavigation from '../components/FixedNavigation';
import RegisterForm from '../components/RegisterForm';
import Footer from '../components/Footer';

import DesktopGetInTouchBotton from '../components/DesktopGetInTouchBotton';
import FixedGetInTouchButton from '../components/FixedGetInTouchButton';
import { Map, Slice } from 'react-lodash';
import _ from 'lodash';
import components from '../ComponentsConfig';

import { useParams } from 'react-router-dom';

gsap.registerPlugin(TextPlugin);

const StaticPages = () => {
  const { t } = useTranslation();

  const dataContext = useContext(DataContext);
  const { getStaticPages, staticPages } = dataContext;
  useEffect(() => {
    getStaticPages();
    // eslint-disable-next-line
  }, []);
  let staticPage,
    sectionsIndex,
    slicer = [];
  const { page } = useParams();
  // console.warn("staticPages >> ", staticPages, page);

  if (staticPages) {
    staticPage = staticPages.find((x) => x.slug === page);
    //  console.warn("staticPage >> ", staticPage);

    if (staticPage) {
      //     console.warn("staticPage.sections >> ", staticPage.sections);
      const sections = _.filter(staticPage.sections, {
        __component: 'components.section-name',
      });
      //    console.warn("sections >> ", sections);
      sectionsIndex = _.map(sections, (s) => {
        return _.findIndex(staticPage.sections, s);
      });
      sectionsIndex.push(staticPage.sections.length);

      if (staticPage.sections.length > 2) {
        for (let index = 0; index <= sectionsIndex.length; index++) {
          slicer.push(_.take(sectionsIndex, 2));
          sectionsIndex = _.drop(sectionsIndex);
        }
      } else {
        slicer.push(_.take(sectionsIndex, 2));
      }
      //   console.warn("slicer >> ", slicer);
    }
  }

  const fixedNavigaitonMobileHandler = (entry, name) => {
    if (entry === true) {
      gsap.to('.fixed-navigation-mobile-handler', {
        duration: 0.3,
        text: `${t($(name)[0].innerHTML)}`,
      });
    }

    // console.log($(name)[0].innerHTML);
  };
  return (
    <Fragment>
      <Promo
        detailClass='detail-promo'
        src={staticPage && staticPage.cover.url}
        title={staticPage && staticPage.name}
        video={staticPage && staticPage.video && staticPage.video.url}
        stream_id={staticPage && staticPage.stream_id}
        hasButton={false}
        buttonTitle={false}
      />
      <main
        className='main-content'
        style={{ backgroundColor: staticPage && staticPage.color }}
      >
        <FixedNavigation
          price={false}
          moneyType={false}
          navigationData={staticPage}
          backgroundColor={staticPage && staticPage.color}
        />
        {/* <FixedGetInTouchButton /> */}
        {/* <DesktopGetInTouchBotton /> */}
        <Map
          collection={staticPage && slicer}
          iteratee={(i) => (
            <Slice array={staticPage.sections} start={i[0] + 1} end={i[1]}>
              {(value) => (
                <InView
                  onChange={(inView, entry) => {
                    fixedActiveNavigationPartDesktop(
                      'section-nav-' + staticPage.sections[i[0]].id,
                      entry.isIntersecting
                    );
                    fixedNavigaitonMobileHandler(
                      entry.isIntersecting,
                      '#section-nav-' + staticPage.sections[i[0]].id
                    );
                  }}
                >
                  <div
                    className='detail-section'
                    id={'section-' + staticPage.sections[i[0]].id}
                    name={'section-' + staticPage.sections[i[0]].id}
                  >
                    {value.map((comp) => {
                      const Component = components.find(
                        (c) => c.name === comp.__component
                      ).component;
                      return (
                        <Component {...comp}>{comp.__component}</Component>
                      );
                    })}
                  </div>
                </InView>
              )}
            </Slice>
          )}
        />
      </main>
      <RegisterForm />
      <Footer />
      {/* <FixedGetInTouchButton /> */}
    </Fragment>
  );
};

export default StaticPages;
