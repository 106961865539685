import React, { useState } from 'react';
import './styles/main.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Companies from './pages/Companies';
import MobileMenu from './components/MobileMenu';
import MobileSearch from './components/MobileSearch';
import Header from './components/Header';
import DataState from './context/DataState';
import Media from './pages/Media';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/TermsAndConditions';
import StaticPages from './pages/StaticPages';

const App = () => {
  // Global Open Menu State
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <DataState>
      <BrowserRouter>
        <Header open={open} handleOpen={handleOpen} />
        <Switch>
          <Route exact path='/' component={Companies} />
          <Route exact path='/our-companies' component={Companies} />
          <Route exact path='/:page' component={StaticPages} />
          <Route exact path='/media' component={Media} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route
            exact
            path='/terms-and-conditions'
            component={TermsAndConditions}
          />
        </Switch>
        <MobileMenu open={open} handleOpen={handleOpen} />
        <MobileSearch />
      </BrowserRouter>
    </DataState>
  );
};

export default App;
