import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { phoneOptions } from '../filteroptions/options';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
const RegisterForm = () => {
  const { t } = useTranslation();

  const [selectedPhone, setSelectedPhone] = useState('+93');

  // Register Form State
  const [user, setUser] = useState({
    fullname: '',
    email: '',
    phone: '',
  });
  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });
  // Phone State
  const handleChangePhone = (selectedPhone) => {
    setSelectedPhone(selectedPhone);
  };
  // İnfo Handle State
  const [info, setInfo] = useState('');

  // Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setUser({
      fullname: '',
      email: '',
      phone: '',
    });
    setInfo(!e.target.checked);
  };
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <div id='register-form'>
        <div className='container'>
          <div className='row justify-content-center get-in-touch'>
            <div className='col-lg-5'>
              <div id='get-in-touch'>
                <h6> {t('GET_IN_TOUCH')}</h6>
                <div id='get-in-touch-items'>
                  <a
                    href='mailto:info@amaal.ae'
                    className='get-in-touch-item'
                  >
                    <svg className='icon icon-mail'>
                      <path
                        d='M29,24 C30.65625,24 32,22.65625 32,21 L32,3 C32,1.34375 30.65625,0 29,0 L3,0 C1.34375,0 0,1.34375 0,3 L0,21 C0,22.65625 1.34375,24 3,24 L29,24 Z M16,16 C14.5499878,16.0250244 12.4625244,14.0062256 11.4124756,13.1687622 C5.32501221,8.33752441 3.36877441,6.74377441 2,5.58752441 L2,3 C2,2.45001221 2.45001221,2 3,2 L29,2 C29.5499878,2 30,2.45001221 30,3 L30,5.58752441 C28.6312256,6.74377441 26.6749878,8.33752441 20.5875244,13.1687622 C19.53125,14.0062256 17.4500122,16.0250244 16,16 Z M29,22 L3,22 C2.45001221,22 2,21.5499878 2,21 L2,8.1875 C3.42498779,9.35626221 5.67498779,11.1624756 10.1687622,14.7312622 C11.4500122,15.7562256 13.7125244,18.0125122 16,18 C18.2750244,18.0187378 20.5187378,15.78125 21.8312378,14.7312622 C26.3250122,11.1624756 28.5750122,9.35626221 30,8.1875 L30,21 C30,21.5499878 29.5499878,22 29,22 Z'
                        id='mail'
                        fill='#fff'
                        fillRule='nonzero'
                      ></path>
                    </svg>
                    {t('MAIL')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
};

export default RegisterForm;
