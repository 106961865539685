import React, {useContext} from 'react';
import DataContext from '../context/dataContext';
import { handleMenuAnimation } from "../animations/animation";

const MobileMenu = ({ handleOpen }) => {
  const dataContext = useContext(DataContext);
  const { menus } = dataContext;
  let mainMenu = [];
  if (menus) {
    console.warn("menus >> ", menus);
    
    menus.map((item) => {
      mainMenu.push(item);
      return mainMenu; // I added return
    });
  }
  return (
    <nav id='mobile-menu'>
       {mainMenu.length > 0 && (
          <ul>
            {mainMenu.map((item) => (
              <li key={item.id}>
                <a href={item.link} target={item.target}>{item.name}</a>
              </li>
            ))}
          </ul>
        )}
    </nav>
  );
};

export default MobileMenu;
